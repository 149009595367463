import { TelemetryEntityType } from "fetch/telemetry";

import { ProjectResponseSearch } from "domain/entities/project";
import { SystemResponse } from "domain/entities/system";
import { Telemetry } from "domain/entities/telemetry";
import { TenantResponseSearch } from "domain/entities/tenant";

import { Optional } from "utils/typeUtils";

export enum EntityType {
  ASSET = "ASSET",
  ALARM = "ALARM",
  ATTRIBUTE = "ATTRIBUTE",
  AUTHORITY = "AUTHORITY",
  AUDIT = "AUDIT",
  CLIENT = "CLIENT",
  COMMAND = "COMMAND",
  COMMAND_LOG = "COMMAND_LOG",
  COMMAND_GROUP = "COMMAND_GROUP",
  CONNECTOR = "CONNECTOR",
  CONNECTOR_LOG = "CONNECTOR_LOG",
  CREDENTIALS = "CREDENTIALS",
  DEVICE = "DEVICE",
  DEVICE_GROUP = "DEVICE_GROUP",
  DEVICE_IMPORT_TEMPLATE = "DEVICE_IMPORT_TEMPLATE",
  DEVICE_IMPORT_TEMPLATE_FIELD = "DEVICE_IMPORT_TEMPLATE_FIELD",
  DEVICE_PROFILE = "DEVICE_PROFILE",
  DEVICE_SPECIFICATION = "DEVICE_SPECIFICATION",
  DEVICE_SPECIFICATION_FIELD = "DEVICE_SPECIFICATION_FIELD",
  DASHBOARD = "DASHBOARD",
  DASHBOARD_VIEW = "DASHBOARD_VIEW",
  ENTITY_VIEW = "ENTITY_VIEW",
  ERROR = "ERROR",
  FAVOURITE = "FAVOURITE",
  GRANT = "GRANT",
  INTEGRATION = "INTEGRATION",
  LOG = "LOG",
  NODE = "NODE",
  OTA_PACKAGE = "OTA_PACKAGE",
  PERMISSION = "PERMISSION",
  PROJECT = "PROJECT",
  DEVICE_PROPERTY = "DEVICE_PROPERTY",
  DEVICE_SPECIFICATION_PROPERTY = "DEVICE_SPECIFICATION_PROPERTY",
  RELATION = "RELATION",
  RESOURCE = "RESOURCE",
  SYSTEM = "SYSTEM",
  SIMULATOR = "SIMULATOR",
  TELEMETRY = "TELEMETRY",
  TENANT = "TENANT",
  TENANT_TEMPLATE = "TENANT_TEMPLATE",
  TRANSITION = "TRANSITION",
  USER = "USER",
  USER_PROFILE = "USER_PROFILE",
  WIDGET = "WIDGET",
  WORKFLOW = "WORKFLOW",
  WORKFLOW_ELEMENT_LOG = "WORKFLOW_ELEMENT_LOG",
  WORKFLOW_LOG = "WORKFLOW_LOG",
  WORKFLOW_VERSION = "WORKFLOW_VERSION",
  NOTIFICATION = "NOTIFICATION",
  FIELD = "FIELD",
  FILE = "FILE",
  MESSAGE = "MESSAGE",
}

export enum CachedEntityTypes {
  ASSET = "ASSET",
  ATTRIBUTE = "ATTRIBUTE",
  AUTHORITY = "AUTHORITY",
  CONNECTOR = "CONNECTOR",
  DEVICE_GROUP = "DEVICE_GROUP",
  DEVICE_PROFILE = "DEVICE_PROFILE",
  DEVICE_PROPERTY = "DEVICE_PROPERTY",
  DEVICE_SPECIFICATION_PROPERTY = "DEVICE_SPECIFICATION_PROPERTY",
  DEVICE_SPECIFICATION = "DEVICE_SPECIFICATION",
  DEVICE = "DEVICE",
  FIELD = "FIELD",
  INTEGRATION = "INTEGRATION",
  PERMISSION = "PERMISSION",
  PROJECT = "PROJECT",
  RESOURCE = "RESOURCE",
  TENANT = "TENANT",
  USER = "USER",
  WIDGET = "WIDGET",
  WORKFLOW = "WORKFLOW",
}

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
}

export enum AuditType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  OPEN = "OPEN",
}

export enum AlarmStatusType {
  ACTIVE_ACKNOWLEDGED = "ACTIVE_ACKNOWLEDGED",
  ACTIVE_UNACKNOWLEDGED = "ACTIVE_UNACKNOWLEDGED",
  CLEARED_ACKNOWLEDGED = "CLEARED_ACKNOWLEDGED",
  CLEARED_UNACKNOWLEDGED = "CLEARED_UNACKNOWLEDGED",
}

export enum AlarmSeverity {
  CRITICAL = "CRITICAL",
  MAJOR = "MAJOR",
  MINOR = "MINOR",
  WARNING = "WARNING",
  UNKNOWN = "UNKNOWN",
}

export enum SearchType {
  ELASTIC = "ELASTIC",
  STANDARD = "STANDARD",
}

export enum ResultType {
  REDUCED = "REDUCED",
  COMPLETE = "COMPLETE",
}

export enum SortingDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TelemetryType {
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  LONG = "LONG",
  DOUBLE = "DOUBLE",
  JSON = "JSON",
  IMAGE = "IMAGE",
}

export enum WidgetType {
  ALARM = "ALARM",
  CARD = "CARD",
  CHART_BAR = "CHART_BAR",
  CHART_DOUGHNUT = "CHART_DOUGHNUT",
  CHART_LINE = "CHART_LINE",
  CHART_RADAR = "CHART_RADAR",
  CONTROL_SWITCH = "CONTROL_SWITCH",
  CONTROL_SLIDER = "CONTROL_SLIDER",
  CONTROL_MULTI_FIELD = "CONTROL_MULTI_FIELD",
  DASHBOARD_LINK = "DASHBOARD_LINK",
  ENTITY_COUNT = "ENTITY_COUNT",
  MAP = "MAP",
  MAP_IMAGE = "MAP_IMAGE",
  TABLE = "TABLE",
  VIDEO = "VIDEO",
  USAGE_CARD = "USAGE_CARD",
  USAGE_CHART_BAR = "USAGE_CHART_BAR",
  USAGE_CHART_DOUGHNUT = "USAGE_CHART_DOUGHNUT",
  USAGE_CHART_LINE = "USAGE_CHART_LINE",
  USAGE_CHART_RADAR = "USAGE_CHART_RADAR",
  NOTIFICATION = "NOTIFICATION",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum WorkflowLogStatus {
  NOT_EXECUTED = "NOT_EXECUTED",
  EXECUTING = "EXECUTING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum WorkflowDurationUnit {
  NANOSECONDS = "NANOSECONDS",
  MICROSECONDS = "MICROSECONDS",
  MILLISECONDS = "MILLISECONDS",
  SECONDS = "SECONDS",
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
}

export enum LogicalOperations {
  AND = "AND",
  OR = "OR",
}

export enum BooleanOperations {
  EQUAL = "==",
  NOT_EQUAL = "!=",
}

export enum NumericOperations {
  EQUAL = "==",
  NOT_EQUAL = "!=",
  GREATER = ">",
  LESS = "<",
  GREATER_OR_EQUAL = ">=",
  LESS_OR_EQUAL = "<=",
}
export type NumericOperator = "==" | "!=" | ">" | "<" | ">=" | "<=";

export type Operation =
  | StringOperations
  | NumericOperations
  | BooleanOperations;

export enum StringOperations {
  EQUAL = "==",
  NOT_EQUAL = "!=",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
  IN = "IN",
  NOT_IN = "NOT_IN",
}
export type StringOperator =
  | "=="
  | "!="
  | "STARTS_WITH"
  | "ENDS_WITH"
  | "CONTAINS"
  | "NOT_CONTAINS"
  | "IN"
  | "NOT_IN";

export type Option<T = string> = {
  label: string;
  value: T;
};

export type Size = {
  width: number;
  height: number;
};

export type Position = {
  x: number;
  y: number;
};

export type WidgetLayout = {
  id: Identifier;
  position: Position;
  size: Size;
};

export type Identifier = {
  id: string;
};

export enum ConfigurationType {
  DEFAULT = "DEFAULT",
}

export enum UnitType {
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  LONG = "LONG",
  DOUBLE = "DOUBLE",
  IMAGE = "IMAGE",
  DATE_TIME = "DATE_TIME",
}

export enum NumberUnitType {
  DOUBLE = "DOUBLE",
  LONG = "LONG",
}

export enum SchemaType {
  JSON = "JSON",
}

export enum TransportConfigurationProtocol {
  MQTT_CLIENT = "MQTT_CLIENT",
  MQTT_SERVER = "MQTT_SERVER",
  HTTP_INBOUND = "HTTP_INBOUND",
  HTTP_OUTBOUND = "HTTP_OUTBOUND",
  CHIRPSTACK = "CHIRPSTACK",
  GRPC_CLIENT = "GRPC_CLIENT",
}

export enum ProvisioningConfigurationType {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  PRE_PROVISIONED = "PRE_PROVISIONED",
}

export enum ValueType {
  Null = "null",
  Boolean = "boolean",
  Integer = "integer",
  Number = "number",
  String = "string",
  Object = "object",
  Array = "array",
}

export enum AggregationFunction {
  NONE = "NONE",
  MIN = "MIN",
  MAX = "MAX",
  SUM = "SUM",
  AVG = "AVG",
  COUNT = "COUNT",
  DELTA = "DELTA",
}

export enum TransportConfigurationPayloadType {
  JSON = "JSON",
}

export enum FieldValueType {
  STRING = "STRING",
  NUMERIC = "NUMERIC",
  BOOLEAN = "BOOLEAN",
  DATE_TIME = "DATE_TIME",
}

export enum SpecificationFieldType {
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  LONG = "LONG",
  DOUBLE = "DOUBLE",
  IMAGE = "IMAGE",
}

export enum AlarmRuleConditionFilterKeyType {
  ATTRIBUTE = "ATTRIBUTE",
  TIME_SERIES = "TIME_SERIES",
  ENTITY_FIELD = "ENTITY_FIELD",
  CONSTANT = "CONSTANT",
}

export enum TimeUnit {
  NANOSECONDS = "NANOSECONDS",
  MICROSECONDS = "MICROSECONDS",
  MILLISECONDS = "MILLISECONDS",
  SECONDS = "SECONDS",
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
}

export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export enum ConditionFieldType {
  FLOAT = "FLOAT",
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
}

export enum ConditionDispatchLogicGate {
  AND = "AND",
  IF = "IF",
}

export enum FieldTransformerType {
  CONCATENATE = "CONCATENATE",
  REPLACE = "REPLACE",
  SUBSTRING = "SUBSTRING",
  TRIM = "TRIM",
}

export enum TimeoutPolicyType {
  ALERT = "ALERT",
}

export enum NodeGroupType {
  GENERAL = "GENERAL",
  ENRICHMENT = "ENRICHMENT",
  FILTER = "FILTER",
  TRANSFORMATION = "TRANSFORMATION",
  ACTION = "ACTION",
}

export enum NodeType {
  START = "START",
  END = "END",
  SWITCH = "SWITCH",
  CALCULATE_DELTA = "CALCULATE_DELTA",
  CALCULATE_TIME_DELTA = "CALCULATE_TIME_DELTA",
  PERSIST_TELEMETRY = "PERSIST_TELEMETRY",
}

export enum CommandResultStatus {
  QUEUED = "QUEUED",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  SUCCESSFUL = "SUCCESSFUL",
  TIMEOUT = "TIMEOUT",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  DELETE = "DELETE",
}

export enum CommandLogResultStatus {
  QUEUED = "QUEUED",
  SENDING = "SENDING",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  ACKNOWLEDGED = "ACKNOWLEDGED",
  FAILED = "FAILED",
  TIMEOUT = "TIMEOUT",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
}

export enum CredentialType {
  BASIC = "BASIC",
  CERTIFICATE = "CERTIFICATE",
  TOKEN = "TOKEN",
  KEY = "KEY",
  OAUTH = "OAUTH",
  ANONYMOUS = "ANONYMOUS",
}

export enum BooleanValues {
  TRUE = "true",
  FALSE = "false",
}

export enum BasicInputType {
  STRING = "STRING",
  DOUBLE = "DOUBLE",
  LONG = "LONG",
  BOOLEAN = "BOOLEAN",
}

export enum AttributeType {
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  DOUBLE = "DOUBLE",
  LONG = "LONG",
  JSON = "JSON",
}

export enum InactivityTimeoutUnit {
  NANOSECONDS = "NANOSECONDS",
  MICROSECONDS = "MICROSECONDS",
  MILLISECONDS = "MILLISECONDS",
  SECONDS = "SECONDS",
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  HALF_DAYS = "HALF_DAYS",
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
  YEARS = "YEARS",
  DECADES = "DECADES",
  CENTURIES = "CENTURIES",
  MILLENNIA = "MILLENNIA",
  ERAS = "ERAS",
  FOREVER = "FOREVER",
}

export enum ScheduleEnum {
  ANY = "ANY",
  SPECIFIC = "SPECIFIC",
  CUSTOM = "CUSTOM",
}

export enum ConditionSpecificationEnum {
  SIMPLE = "SIMPLE",
  DURATION = "DURATION",
  REPEATING = "REPEATING",
}

export enum SnackbarMessageType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export enum TransportConfigurationCredentialsType {
  ANONYMOUS = "ANONYMOUS",
  BASIC = "BASIC",
  OAUTH = "OAUTH",
  KEY = "KEY",
  TOKEN = "TOKEN",
  CERTIFICATE = "CERTIFICATE",
}

export enum QualityOfService {
  AT_MOST_ONCE = "AT_MOST_ONCE",
  AT_LEAST_ONCE = "AT_LEAST_ONCE",
  EXACTLY_ONCE = "EXACTLY_ONCE",
}

export enum IntegrationStatus {
  STARTED = "STARTED",
  STOPPED = "STOPPED",
  RUNNING = "RUNNING",
  ERROR = "ERROR",
}

export enum DatasourceType {
  ASSET = "ASSET",
  DEVICE_SPECIFICATION = "DEVICE SPECIFICATION",
}

export enum NotificationLevel {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export type PayloadConfiguration = {
  type: SchemaType;
  name: string;
  description: string;
  schema: string;
  response: string;
};

export type PayloadConfigurationSchema = {
  payloadConfiguration: PayloadConfiguration;
};

export type TransportConfigurationCredentials = {
  type: TransportConfigurationCredentialsType;
  username?: string;
  password?: string;
  clientId?: string;
  value?: string;
};

export type TransportPayloadSchema = {
  schemaType: SchemaType;
  name: string;
  description: string;
  schema: string;
};

export type TransportPayloadConfiguration = {
  payloadSchema: TransportPayloadSchema;
  response: string;
  payloadType: TransportConfigurationPayloadType;
};

export interface BaseTransportConfiguration {
  protocol: TransportConfigurationProtocol;
}

export interface TransportConfigurationHttpInbound
  extends BaseTransportConfiguration {
  request: {
    schema: {
      type: SchemaType;
      value: string;
    };
  };
  response: { payload: { type: string; value: string } };
}

export interface TransportConfigurationHttpOutbound
  extends BaseTransportConfiguration {
  request: {
    baseUrl: string;
    path: string;
    method: HttpMethod;
    headers: Record<string, string[]>;
    timeout: string;
    credentials: TransportConfigurationCredentials;
    queryParameters: Record<string, string[]>;
    pathVariables: Record<string, string[]>;
    body: string;
  };
  response: {
    schema: {
      type: SchemaType;
      value: string;
    };
  };
  interval: string;
}

export interface TransportConfigurationMqttClient
  extends BaseTransportConfiguration {
  host: string;
  port: number;
  topic: string;
  qualityOfService: QualityOfService;
  schema: {
    value: string;
    type: SchemaType;
  };
  credentials: TransportConfigurationCredentials;
}

export interface TransportConfigurationGrpcClient
  extends BaseTransportConfiguration {
  host: string;
  port: number;
  schema: {
    value: string;
    type: SchemaType;
  };
  token: string;
  timeout: string;
  method: string;
}

export interface TransportConfigurationMqttServer
  extends BaseTransportConfiguration {
  schema: {
    value: string;
    type: SchemaType;
  };
}

export interface TransportConfigurationChirpstack
  extends BaseTransportConfiguration {
  host: string;
  port: number;
  token: string;
  timeout: string;
  schema: {
    value: string;
    type: SchemaType;
  };
  plainText: boolean;
  confirmed: boolean;
}

export type TransportConfiguration =
  | TransportConfigurationHttpInbound
  | TransportConfigurationHttpOutbound
  | TransportConfigurationMqttClient
  | TransportConfigurationMqttServer
  | TransportConfigurationChirpstack;

export type TransportConfigurationSchema = {
  transportConfiguration: TransportConfiguration;
};

export type EntityId = {
  id: string;
  type: EntityType;
};

export type EntityReference = EntityId & {
  name: string;
};

export type Address = {
  country: string;
  state: string;
  zipCode: number;
  city: string;
  street: string;
  number: string;
};

export type Contact = {
  address: Address;
  phone: string;
  email: string;
};

export type UsageLimitType = {
  alarms: number;
  dataPoints: number;
  messages: number;
  storage: string;
  workflows: number;
};

export interface EntityTelemetry {
  telemetryEntityId: EntityId;
  telemetry: Telemetry;
}

export declare interface PayloadPost {
  name: string;
  description: string;
}

export declare interface PayloadGet extends PayloadPost {
  identifier: EntityId;
  created: string;
}

export enum ResponseCodeCategory {
  SUCCESSFUL = "SUCCESSFUL",
  TECHNICAL_ERROR = "TECHNICAL_ERROR",
  BUSINESS_ERROR = "BUSINESS_ERROR",
  OTHER = "OTHER",
}

type ResponseCodeObject = {
  name: string;
  code: number;
  category: ResponseCodeCategory;
  description: string;
};

export declare interface ResponseWrapper {
  responseCode: number;
  responseDetail: string;
  payload: unknown;
  responseCodeObject: ResponseCodeObject;
  successful: boolean;
}

export declare interface ResponseWrapperTyped<T> {
  responseCode: number;
  responseDetail: string;
  payload: T;
  responseCodeObject: ResponseCodeObject;
  successful: boolean;
}

export declare interface ResponseWrapperSearch extends ResponseWrapper {
  recordsPerPage: number;
  page: number;
  numberOfPages: number;
  numberOfRecords: number;
}

export declare interface BaseResponse extends ResponseWrapper {
  payload: PayloadGet;
}

export declare interface BaseResponseSearch extends ResponseWrapperSearch {
  payload: Array<PayloadGet>;
}

export declare interface BaseObject {
  name: string;
  image: string;
  isLoading: boolean;
  description?: string;
  locale?: { code: string };
  loginImage?: string;
  contact?: {
    address: {
      country: string;
      state: string;
      zipCode: number;
      city: string;
      street: string;
      number: string;
    };
    phone: string;
    email: string;
  };
}

export declare interface TenantProjectObject extends BaseObject {
  id: string;
}

export declare interface BadRequest {
  detail: string;
  instance: string;
  status: number;
  title: string;
  type: string;
  error?: string;
}

export type AlarmStatusEvents = {
  started: string;
  ended: string;
  acknowledged: string;
  cleared: string;
};

export type AlarmPropagation = {
  propagated: boolean;
  propagatedToProject: boolean;
  propagatedToTenant: boolean;
};

export type Alarm = {
  identifier: EntityId;
  tenantId: EntityId;
  projectId: EntityId;
  trigger: EntityId;
  type: string;
  severity: AlarmSeverity;
  status: AlarmStatusType;
  events: AlarmStatusEvents;
  propagation: AlarmPropagation;
};

export type Attribute = {
  entityId: {
    id: string;
    type: EntityType;
  };
  identifier: {
    id: string;
    type: EntityType;
  };
  name: string;
  type: AttributeType;
  value: string;
  created: string;
};

export type Schema = {
  $schema?: string;
  type?: ValueType | ValueType[];
  "x-field"?: string;
  items?: Schema;
  properties?: Record<string, Schema>;
  required?: string[];
  anyOf?: Array<Schema>;
  additionalProperties?: boolean;
};

export type SchemaGenOptions = {
  noRequired: boolean;
};

export type SchemaComparisonOptions = {
  ignoreRequired: boolean;
};

export type Key = {
  id: string;
  name: string;
};

export type DataSource = {
  entityType: TelemetryEntityType;
  entities: Key[];
  fields: string[];
  from: string;
  to: string;
  dynamic: boolean;
  aggregation?: AggregationFunction;
  specificationId: EntityId;
};

export type SystemData = {
  application: Optional<SystemResponse>;
  projects: Optional<ProjectResponseSearch>;
  tenants: Optional<TenantResponseSearch>;
};

export enum RelationType {
  CONTAINS = "CONTAINS",
  MANAGES = "MANAGES",
  OWNS = "OWNS",
}

export enum SnackbarPosition {
  TOP_CENTER = '{"vertical": "top", "horizontal": "center"}',
  TOP_LEFT = '{"vertical": "top", "horizontal": "left"}',
  TOP_RIGHT = '{"vertical": "top", "horizontal": "right"}',
  BOTTOM_LEFT = '{"vertical": "bottom", "horizontal": "left"}',
  BOTTOM_CENTER = '{"vertical": "bottom", "horizontal": "center"}',
  BOTTOM_RIGHT = '{"vertical": "bottom", "horizontal": "right"}',
}

export type TimeRange = {
  from: string;
  to: string;
};
export type TimeRangeIntervalsType = Array<TimeRange>;

export enum Status {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  UNKNOWN = "UNKNOWN",
}

export enum IntegrationLogStatus {
  OK = "OK",
  ERROR = "ERROR",
}

export type ActiveScheduleType = {
  dayOfWeek: DayOfWeek;
  active: boolean;
};

export enum TelemetryGroupBy {
  FIELD = "FIELD",
  TIMESTAMP = "TIMESTAMP",
}

export enum OtaType {
  FIRMWARE = "FIRMWARE",
  SOFTWARE = "SOFTWARE",
}

export enum ChecksumAlgorithmType {
  MD5 = "MD5",
  SHA256 = "SHA256",
  SHA384 = "SHA384",
  SHA512 = "SHA512",
  CRC32 = "CRC32",
  MURMUR3_32 = "MURMUR3_32",
  MURMUR3_128 = "MURMUR3_128",
}

export interface ReferenceQueryParams {
  projectId: string;
}

export interface ReferencePayloadGet {
  id: string;
  name: string;
}

export interface CommandLogIdentifier {
  id: string;
  name: string;
}

export interface DeviceFieldsRadarChart {
  name: string;
  value: string;
  type: FieldValueType;
  description: string;
  unit: string;
}

export interface ImportResponse {
  responseCode: number;
  responseDetail: string;
  entities: number;
  successful: boolean;
  responseCodeObject: {
    name: string;
    code: number;
    category: string;
    description: string;
  };
}

export const DeviceFieldsRadarChartDataSource: DeviceFieldsRadarChart[] = [
  {
    name: "NAME",
    value: "name",
    type: FieldValueType.STRING,
    description: "",
    unit: "",
  },
  {
    name: "STATUS_CREATED",
    value: "status.created",
    type: FieldValueType.DATE_TIME,
    description: "",
    unit: "",
  },
  {
    name: "STATUS_INSTALLED",
    value: "status.installed",
    type: FieldValueType.BOOLEAN,
    description: "",
    unit: "",
  },
];

export const DeviceFieldsChartDataSource: DeviceFieldsRadarChart[] = [
  {
    name: "NAME",
    value: "name",
    type: FieldValueType.STRING,
    description: "",
    unit: "",
  },
  {
    name: "DESCRIPTION",
    value: "description",
    type: FieldValueType.STRING,
    description: "",
    unit: "",
  },
  {
    name: "TYPE",
    value: "type",
    type: FieldValueType.STRING,
    description: "",
    unit: "",
  },
  {
    name: "EXTERNAL_ID",
    value: "externalId",
    type: FieldValueType.STRING,
    description: "",
    unit: "",
  },
  {
    name: "STATUS_CREATED",
    value: "status.created",
    type: FieldValueType.DATE_TIME,
    description: "",
    unit: "",
  },
  {
    name: "STATUS_INSTALLED",
    value: "status.installed",
    type: FieldValueType.BOOLEAN,
    description: "",
    unit: "",
  },
  {
    name: "STATUS",
    value: "status.status",
    type: FieldValueType.STRING,
    description: "",
    unit: "",
  },
];

export enum DatasetTitleRadarChartOptions {
  NAME = "name",
  DESCRIPTION = "description",
  TYPE = "type",
  EXTERNAL_ID = "externalId",
  STATUS_CREATED = "status.created",
  STATUS_INSTALLED = "status.installed",
  STATUS_STATUS = "status.status",
}

export type ReturnType<T extends (...args: any[]) => any> = T extends (
  ...args: any[]
) => infer R
  ? R
  : any;

export type NestedObject = {
  [key: string]: any;
};

export enum DeviceSpecificationFieldFormatterType {
  DATE_TIME = "DATE_TIME",
  DOUBLE = "DOUBLE",
}

export enum FieldRangeType {
  INTEGER = "INTEGER",
  LONG = "LONG",
  DOUBLE = "DOUBLE",
  STRING = "STRING",
}

export enum AuthenticatedClientScope {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum SystemField {
  EXTERNAL_DEVICE_ID = "EXTERNAL_DEVICE_ID",
  INTERNAL_DEVICE_ID = "INTERNAL_DEVICE_ID",
  EXTERNAL_DEVICE_PROFILE_ID = "EXTERNAL_DEVICE_PROFILE_ID",
  INTERNAL_DEVICE_PROFILE_ID = "INTERNAL_DEVICE_PROFILE_ID",
  GATEWAY_ID = "GATEWAY_ID",
  INTEGRATION_ID = "INTEGRATION_ID",
  TIMESTAMP = "TIMESTAMP",
  LATITUDE = "LATITUDE",
  LONGITUDE = "LONGITUDE",
  CORRELATION = "CORRELATION",
  ACKNOWLEDGEMENT = "ACKNOWLEDGEMENT",
}

export enum DecoderType {
  SIMPLE = "SIMPLE",
  COMPLEX = "COMPLEX",
}

export enum TransformerMathOperationType {
  JSON_TO_HEX = "JSON_TO_HEX",
  BASE64_TO_HEX = "BASE64_TO_HEX",
  HEX_TO_DECIMAL_SIGNED = "HEX_TO_DECIMAL_SIGNED",
  HEX_TO_DECIMAL_UNSIGNED = "HEX_TO_DECIMAL_UNSIGNED",
  EXTRACT_BYTES = "EXTRACT_BYTES",
  SUBSTRING = "SUBSTRING",
  MULTIPLY = "MULTIPLY",
  DIVIDE = "DIVIDE",
  ADD = "ADD",
  SUBTRACT = "SUBTRACT",
  POWER = "POWER",
  SCALE = "SCALE",
  ROUND = "ROUND",
  OFFSET = "OFFSET",
}

export enum ConnectorPayload {
  JSON = "JSON",
  XML = "XML",
  HEX = "HEX",
}

export enum ExistenceCheck {
  "EXISTS" = "EXISTS",
  "NOT_EXISTS" = "NOT_EXISTS",
}

export type AutoCompleteOption<T> = {
  value: T;
  label: string;
};

export interface ExportRequestBody {
  ids: string[];
}
